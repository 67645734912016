import { Blacklist } from "@/models/blacklist.model";
import BaseService from "./base-service";

class BlacklistService extends BaseService<Blacklist> {
  get endpoint() {
    return "/blacklist";
  }
}

export default new BlacklistService();
