import axios from "@/plugins/axios.plugin";
import BaseService from "./base-service";

class SettingsService extends BaseService<any> {
  get endpoint() {
    return "/settings";
  }

  async fetchOnePublic(id: string): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/public/${id}`);
    return response.data;
  }
  async fetchOneParsed(id: string): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/${id}/parsed`);
    return response.data;
  }

  async importTranslations(file: any): Promise<any> {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(
      `${this.endpoint}/translations/import`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "json",
      }
    );
    return response.data;
  }
}

export default new SettingsService();
