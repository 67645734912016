import { BaseModel } from "./base.model";
import { Translatable } from "./translatable.model";

export class Media extends BaseModel {
  constructor(
    public title: Translatable = new Translatable(),
    public description: Translatable = new Translatable(),
    public image: string = "",
    public media_album_id?: string
  ) {
    super();
  }
}
