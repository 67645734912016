import axios from "@/plugins/axios.plugin";
import BaseService from "./base-service";

class MailsService extends BaseService<any> {
  get endpoint() {
    return "/mails";
  }

  async sendMail(payload: any): Promise<any> {
    const response = await axios.post<any>(`${this.endpoint}/send`, payload);
    return response.data;
  }

  async sendMailToFilter(payload: any): Promise<any> {
    console.log(payload);
    const response = await axios.post<any>(
      `${this.endpoint}/send-to-filter`,
      payload
    );
    return response.data;
  }

  async fetchByTemplate(template: string): Promise<any> {
    const response = await axios.get<any>(
      `${this.endpoint}/by-template/${template}`
    );
    return response.data;
  }

  async fetchByRecipient(id: string): Promise<any> {
    const response = await axios.get<any>(
      `${this.endpoint}/by-recipient/${id}`
    );
    return response.data;
  }

  async validateMailbox(mailbox: string): Promise<boolean> {
    const response = await axios.get<any>(
      `${this.endpoint}/validate-mailbox/${mailbox}`
    );
    return response.data;
  }
}

export default new MailsService();
