import { Media } from "@/models/media.model";
import BaseService from "./base-service";

class MediaService extends BaseService<Media> {
  get endpoint() {
    return "/media";
  }
}

export default new MediaService();
