import { MediaAlbum } from "@/models/media-album.model";
import BaseService from "./base-service";

class MediaAlbumService extends BaseService<MediaAlbum> {
  get endpoint() {
    return "/media-albums";
  }
}

export default new MediaAlbumService();
