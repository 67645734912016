import { Blacklist } from "@/models/blacklist.model";
import BlacklistService from "@/services/BlacklistService";

interface BlacklistStateInterface {
  all: Blacklist[];
}

const BlacklistModule = {
  namespaced: true,
  state: {
    all: [],
  },
  getters: {
    all: (state: BlacklistStateInterface) => state.all,
  },
  mutations: {
    SET_ALL(state: BlacklistStateInterface, all: Blacklist[]) {
      state.all = all;
    },
  },
  actions: {
    async fetchAll({ commit }: any) {
      const blacklist = await BlacklistService.fetchAllUnpaginated();
      commit("SET_ALL", blacklist);
    },
    async delete({ commit }: any, id: string) {
      await BlacklistService.delete(id);
    },
  },
} as any;

export default BlacklistModule;
