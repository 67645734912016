import { BaseModel } from "./base.model";

export class EventSession extends BaseModel {
  constructor(
    public start: { [lang: string]: string } = {},
    public stop: { [lang: string]: string } = {},
    public title: { [lang: string]: string } = {},
    public description: { [lang: string]: string } = {},
    public image_url: { [lang: string]: string } = {},
    public url: { [lang: string]: string } = {},
    public target?: string
  ) {
    super();
  }
}
