import { BaseModel } from "./base.model";

export class Payment extends BaseModel {
  constructor(
    public user_id = "",
    public amount = 0,
    public description?: string,
    public payment_method?: string,
    public is_paid: boolean = false,
    public transaction_id?: string,
    public redirect_url?: string
  ) {
    super();
  }
}
