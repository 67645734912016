import { SocialPost } from "@/models/social-post.model";
import axios from "@/plugins/axios.plugin";
import { PaginatedResult } from "@/shared/types/paginated-result.class";
import BaseService from "./base-service";
import { SocialPostLike } from "@/models/social-post-like.model";

class SocialPostsService extends BaseService<SocialPost> {
  get endpoint() {
    return "/socialposts";
  }

  async fetchAllByTopic(topic?: string): Promise<PaginatedResult<SocialPost>> {
    const response = await axios.get<PaginatedResult<SocialPost>>(
      `${this.endpoint}${topic ? `/${topic}` : ""}`
    );

    return response.data;
  }
  async like(id: string): Promise<SocialPost> {
    const response = await axios.post<SocialPost>(
      `${this.endpoint}/${id}/like`
    );

    return response.data;
  }

  async unlike(id: string): Promise<SocialPost> {
    const response = await axios.delete<SocialPost>(
      `${this.endpoint}/${id}/unlike`
    );

    return response.data;
  }

  async fetchLikes(id: string): Promise<SocialPostLike[]> {
    const response = await axios.get<SocialPostLike[]>(
      `${this.endpoint}/${id}/likes`
    );

    return response.data;
  }
}

export default new SocialPostsService();
