import { EventSession } from "@/models/event-session.model";
import BaseService from "./base-service";

class EventSessionsService extends BaseService<EventSession> {
  get endpoint() {
    return "/event-sessions";
  }
}

export default new EventSessionsService();
