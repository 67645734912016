import axios from "@/plugins/axios.plugin";
import { StaticPage } from "@/models/static-page.model";
import BaseService from "./base-service";

class StaticPagesService extends BaseService<StaticPage> {
  get endpoint() {
    return "/static-pages";
  }

  async fetchByLink(link: string, token?: string): Promise<StaticPage> {
    const response = await axios.get<StaticPage>(
      `${this.endpoint}/${link}/link${token ? `?token=${token}` : ""}`
    );

    return response.data;
  }
}

export default new StaticPagesService();
