import SettingsService from "@/services/SettingsService";

interface FeaturesState {
  all: { [key: string]: any };
}

const Features = {
  namespaced: true,
  state: {
    all: {},
  },
  getters: {
    all: (state: FeaturesState) => state.all,
    loginRequired: (state: FeaturesState) =>
      state.all?.authentication?.type !== "none",
    sponsors: (state: FeaturesState) => state.all?.sponsors,
    authenticationType: (state: FeaturesState) =>
      state.all?.authentication?.type,
    userProfile: (state: FeaturesState) => state.all?.userProfile,
    expandedLogin: (state: FeaturesState) => state.all?.expandedLogin,
    whoIsWho: (state: FeaturesState) => state.all?.whoIsWho,
    disableForgotPassword: (state: FeaturesState) =>
      state.all?.disableForgotPassword,
  },
  mutations: {
    SET_ALL(state: FeaturesState, features: any) {
      state.all = features;
    },
  },
  actions: {
    async fetchAll({ commit }: any, filter: any) {
      const features = await SettingsService.fetchOnePublic("features");
      commit("SET_ALL", features.setting_value);
    },
    async update({ commit }: any, payload: any) {
      const result = await SettingsService.update("features", payload);
    },
  },
} as any;

export default Features;
