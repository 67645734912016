import axios from "@/plugins/axios.plugin";
import { StaticPage } from "@/models/static-page.model";
import BaseService from "./base-service";
import { User } from "@/models/user.model";
import { UserFilter } from "@/models/user-filter.model";
import { AvailableField } from "@/models/available-field.model";

class UserFiltersService extends BaseService<UserFilter> {
  get endpoint() {
    return "/user-filters";
  }

  async fetchUsersForFilter(id: string): Promise<User[]> {
    const response = await axios.get<User[]>(`${this.endpoint}/${id}/users`);

    return response.data;
  }

  async fetchCount(id: string): Promise<number> {
    const response = await axios.get<number>(`${this.endpoint}/${id}/count`);

    return response.data;
  }

  async fetchAvailableFields(): Promise<AvailableField[]> {
    const response = await axios.get<AvailableField[]>(
      `${this.endpoint}/available-fields`
    );

    return response.data;
  }

  async export(id: string, language: string): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/${id}/export`, {
      responseType: "arraybuffer",
      params: { language },
    });
    return response.data;
  }
}

export default new UserFiltersService();
