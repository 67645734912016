import { MediaAlbum } from "@/models/media-album.model";
import MediaAlbumsService from "@/services/MediaAlbumService";
import Vue from "vue";

interface MediaAlbumsState {
  all: MediaAlbum[];
  current: MediaAlbum;
}

const MediaAlbumsStore = {
  namespaced: true,
  state: {
    all: [],
    current: new MediaAlbum(),
  },
  getters: {
    all: (state: MediaAlbumsState) => state.all,
    current: (state: MediaAlbumsState) => state.current,
  },
  mutations: {
    SET_ALL(state: MediaAlbumsState, mediaalbum: MediaAlbum[]) {
      state.all = mediaalbum;
    },
    SET_CURRENT(state: MediaAlbumsState, mediaalbums: MediaAlbum) {
      Vue.set(state, "current", mediaalbums);
    },
  },
  actions: {
    async fetchAll({ commit }: any) {
      const mediaalbums = await MediaAlbumsService.fetchAllUnpaginated();
      commit("SET_ALL", mediaalbums);
    },
    async create({ commit }: any, data: MediaAlbum) {
      const response = await MediaAlbumsService.create(data);
      commit("SET_CURRENT", response);
      return response;
    },
    async updateById({ commit }: any, data: MediaAlbum) {
      const response = await MediaAlbumsService.update(data.id as string, data);
      return response;
    },
    async delete({ commit }: any, id: string) {
      const response = await MediaAlbumsService.delete(id);
    },
    async fetchById({ commit }: any, id: string) {
      const response = await MediaAlbumsService.fetchOne(id);
      commit("SET_CURRENT", response);
      return response;
    },
  },
} as any;

export default MediaAlbumsStore;
