export enum StatisticsCardType {
  Statistic = "STATISTICS",
  UserFilterCount = "USERFILTERCOUNT",
  UserFilterSum = "USERFILTERSUM",
}

export enum StatisticType {
  Login = "login",
  UniqueLogin = "uniqueLogin",
  Views = "views",
  NewUserCount = "new-users-count",
  SocialPosts = "social-posts",
  Registrations = "registrations",
  NewUsers = "new-users",
  Attendees = "attendees",
  Revenue = "revenue",
}

export class StatisticsCard {
  constructor(
    public title: string = "",
    public type: StatisticsCardType = StatisticsCardType.Statistic,
    public source: string = StatisticType.Views,
    public icon?: string,
    public link?: string
  ) {}
}

export class StatisticsConfig {
  constructor(public cards: StatisticsCard[] = []) {}
}
