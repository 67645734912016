
















































































import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import { NavItem } from "../shared/types/nav-item.class";
import { Watch } from "vue-property-decorator";
import { User } from "@/models/user.model";

const authModule = namespace("Auth");
const featuresModule = namespace("Features");
const stylingModule = namespace("Styling");

@Component({})
export default class SideMenu extends Vue {
  moderatorPages: string[] = [
    "static-pages",
    "home-config",
    "users",
    "workshops",
    "statistics",
  ];
  navItems: NavItem[] = [
    new NavItem("dashboard", "statistics", "tachometer-alt"),
    new NavItem("media", undefined, "image", [
      new NavItem("media", "media"),
      new NavItem("media-albums", "media-albums"),
    ]),
    new NavItem("users", undefined, "users", [
      new NavItem("overview", "users"),
      new NavItem("user-filters", "user-filters"),
      new NavItem("user-roles", "user-roles"),
      new NavItem("payments", "payments"),
      new NavItem("logs", "user-logs"),
    ]),
    new NavItem("subscriptions", undefined, "edit", [
      new NavItem("registration-forms", "registration-config"),
      new NavItem("workshops", "workshops"),
    ]),
    new NavItem("tickets", undefined, "ticket-alt", [
      new NavItem("templates", "ticket-templates"),
      new NavItem("generate-individual", "generate-ticket"),
      new NavItem("generate-group", "generate-ticket-for-group"),
    ]),
    new NavItem("communication", undefined, "phone", [
      new NavItem("notifications", "notifications"),
      new NavItem("e-mail-templates", "mails"),
      new NavItem("mail-to-group", "sendMailToGroup"),
      new NavItem("mail-to-individual", "sendMail"),
      new NavItem("default-mails", "default-mails"),
      new NavItem("failed-mails", "blacklist"),
      new NavItem("social-walls", "socialpost-topics"),
    ]),
    new NavItem("calendar", undefined, "calendar", [
      new NavItem("events", "event-sessions"),
      new NavItem("schedules", "schedule-config"),
    ]),
    new NavItem("pages", undefined, "file-alt", [
      new NavItem("home-page", "home-config"),
      new NavItem("general-pages", "static-pages"),
      new NavItem("livestreams", "livestream-config"),
      new NavItem("rooms", "room-config"),
    ]),
    new NavItem("configuration", undefined, "cog", [
      new NavItem("settings", "basic-info"),
      new NavItem("styling", "style-config"),
      new NavItem("header", "header-config"),
      new NavItem("footer", "sponsors-config"),
      new NavItem("legal", "legal-config"),
      new NavItem("mailing-settings", "feature-config"),
      new NavItem("payment-settings", "payment-config"),
      new NavItem("statistics-settings", "statistics-config"),
      new NavItem("authentication", "feature-config"),
      new NavItem("custom-css", "custom-css"),
      new NavItem("translations-config", "translations-config"),
      new NavItem("whitelist", "whitelist"),
      new NavItem("helpdesk", "freshchat-config"),
    ]),
  ];

  get currentRoute() {
    return this.$route.name as string;
  }

  get isModerator() {
    return this.currentUser?.role === "moderator";
  }

  get baseUrl() {
    return process.env.VUE_APP_BASE_URL;
  }

  @featuresModule.Getter("all")
  features!: any;

  @stylingModule.Getter("logo")
  logo!: string;

  @authModule.Mutation("logout")
  clearData!: () => void;

  @authModule.Getter("currentUser")
  currentUser!: User;

  canSee(item: NavItem) {
    if (!this.isModerator) {
      return true;
    }
    if (item.hasChildren) {
      for (const childNavItem of item.children) {
        if (this.canSee(childNavItem)) {
          return true;
        }
      }
    }
    if (!item.route) {
      return false;
    }
    return this.moderatorPages.includes(item.route);
  }

  logout() {
    this.clearData();
    this.$router.push({ name: "admin-login" });
  }
}
