import { BaseModel } from "./base.model";

export class SocialpostTopic extends BaseModel {
  constructor(
    public active: boolean = true,
    public title: { [lang: string]: string } = {},
    public description: { [lang: string]: string } = {}
  ) {
    super();
  }
}
