import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAddressBook,
  faAngleDown,
  faAngleUp,
  faArrowCircleRight,
  faArrowDown,
  faArrowLeft,
  faArrowUp,
  faArrowsAlt,
  faBars,
  faBell,
  faCalendar,
  faCamera,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleNotch,
  faClock,
  faClone,
  faCog,
  faComment,
  faComments,
  faCopy,
  faDoorOpen,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faFileAlt,
  faFilePdf,
  faGripVertical,
  faHammer,
  faHandshake,
  faHashtag,
  faHeadset,
  faHome,
  faImage,
  faLanguage,
  faLink,
  faLock,
  faMobile,
  faPalette,
  faPaperPlane,
  faPaperclip,
  faPencilAlt,
  faPhone,
  faPlayCircle,
  faPlusSquare,
  faQrcode,
  faSignInAlt,
  faSignOutAlt,
  faTachometerAlt,
  faTags,
  faTicketAlt,
  faTimes,
  faTimesCircle,
  faToggleOn,
  faTrash,
  faUser,
  faUserPlus,
  faUserSecret,
  faUsers,
  faVideo,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Vue from "vue";

library.add(
  faThumbsUp,
  faUserPlus,
  faComment,
  faLink,
  faClone,
  faUserSecret,
  faUser,
  faArrowLeft,
  faVideo,
  faComments,
  faPaperclip,
  faFilePdf,
  faMobile,
  faSignInAlt,
  faSignOutAlt,
  faHashtag,
  faTimesCircle,
  faCopy,
  faClock,
  faHammer,
  faLock,
  faPalette,
  faArrowsAlt,
  faTimes,
  faGripVertical,
  faChevronDown,
  faTrash,
  faEdit,
  faPencilAlt,
  faToggleOn,
  faBell,
  faFileAlt,
  faBars,
  faHeadset,
  faUsers,
  faTags,
  faPaperclip,
  faLanguage,
  faHandshake,
  faPlusSquare,
  faPaperPlane,
  faEnvelope,
  faQrcode,
  faHome,
  faCamera,
  faEllipsisV,
  faDoorOpen,
  faCalendar,
  faArrowCircleRight,
  faPlayCircle,
  faChevronRight,
  faAngleDown,
  faAngleUp,
  faArrowsAlt,
  faChevronLeft,
  faTachometerAlt,
  faImage,
  faCircle,
  faTicketAlt,
  faPhone,
  faCalendar,
  faCog,
  faAddressBook,
  faFileAlt,
  faCircleNotch,
  faArrowUp,
  faArrowDown
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
