import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { domain, clientId, audience } from "../auth_config.json";
import { Auth0Plugin } from "./auth0";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { i18n } from "@/plugins/i18n";
import "@/plugins/gtag.plugin";
import "@/plugins/vue-formulate.plugin";
import "@/plugins/font-awesome.plugin";
import "@/plugins/recaptcha.plugin";
import "./plugins/vue-select.plugin";
import FlagIcon from "vue-flag-icon";
import "vue-search-select/dist/VueSearchSelect.css";

import "@/plugins/vee-validate.plugin";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import VueMeta from "vue-meta";
import VueCarousel from "vue-carousel";
import ToggleButton from "vue-js-toggle-button";
import VueScrollactive from "vue-scrollactive";
import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import CountryFlag from "vue-country-flag";
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import SideBar from "./layouts/SideBar.vue";
import NoSidebar from "./layouts/NoSideBar.vue";
import AceEditor from "vuejs-ace-editor";

Vue.component("sidebar-layout", SideBar);
Vue.component("no-sidebar-layout", NoSidebar);

import "./assets/css/custom.scss";
import "./assets/css/tribespot.scss";

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

Vue.use(require("vue-moment"));
Vue.use(VueCarousel);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(FlagIcon);
Vue.use(VueMeta);
Vue.use(VueScrollactive);
Vue.use(VueSidebarMenu);
Vue.component("AceEditor", AceEditor);
Vue.use(VueQuillEditor);
Vue.component(VueQrcode.name, VueQrcode);
Vue.component("country-flag", CountryFlag);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
