import { EventSession } from "@/models/event-session.model";
import EventSessionsService from "@/services/EventSessionsService";
import Vue from "vue";

interface EventSessionsState {
  all: EventSession[];
  current: EventSession;
}

const Pages = {
  namespaced: true,
  state: {
    all: [],
    current: new EventSession(),
  },
  getters: {
    all: (state: EventSessionsState) => state.all,
    current: (state: EventSessionsState) => state.current,
  },
  mutations: {
    SET_ALL(state: EventSessionsState, pages: any) {
      state.all = pages;
    },
    SET_CURRENT(state: EventSessionsState, page: EventSession) {
      Vue.set(state, "current", page);
    },
  },
  actions: {
    async fetchAll({ commit }: any) {
      const eventSessions = await EventSessionsService.fetchAllUnpaginated();
      commit("SET_ALL", eventSessions);
    },
    async create({ commit }: any, data: EventSession) {
      const response = await EventSessionsService.create(data);
      commit("SET_CURRENT", response);
      return response;
    },
    async update({ commit }: any, data: EventSession) {
      const response = await EventSessionsService.update(
        data.id as string,
        data
      );
      commit("SET_CURRENT", response);
      return response;
    },
    async delete({ commit }: any, id: string) {
      const response = await EventSessionsService.delete(id);
    },
    async fetchById({ commit }: any, id: string) {
      const response = await EventSessionsService.fetchOne(id);
      commit("SET_CURRENT", response);
      return response;
    },
  },
} as any;

export default Pages;
