export class NavItem {
  constructor(
    public title: string,
    public route?: string,
    public icon: string = "circle-notch",
    public children: NavItem[] = []
  ) {}

  get hasChildren() {
    return this.children && this.children.length > 0;
  }
}
