import axios from "@/plugins/axios.plugin";
import BaseService from "./base-service";

class LogsService extends BaseService<any> {
  get endpoint() {
    return "/user_logs";
  }

  async sync(): Promise<void> {
    const response = await axios.post<void>(`${this.endpoint}/sync`);
    return response.data;
  }
}

export default new LogsService();
