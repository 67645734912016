import { BaseModel } from "./base.model";

export enum CastType {
  Number = "Number",
  Date = "Date",
  Boolean = "Boolean",
  ObjectId = "ObjectId",
}

export enum Operator {
  Equal = "=",
  GreaterThan = ">",
  GreaterThanOrEqual = ">=",
  LessThan = "<",
  LessThanOrEqual = "<=",
  Unequal = "<>",
  Exists = "",
  DoesNotExist = "!",
  Like = "like",
  Attends = "=attends",
  DoesNotAttend = "<>attends",
}

export class FilterOperation {
  constructor(
    public field: string,
    public value?: number | string | boolean | number[] | string[],
    public operator: Operator = Operator.Equal,
    public condition = "and"
  ) {}
}

export class UserFilter extends BaseModel {
  constructor(
    public name?: string,
    public filter: FilterOperation[] = [],
    public countLogic?: string
  ) {
    super();
  }
}
