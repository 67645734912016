




import Vue from "vue";
import Component from "vue-class-component";
import { bootstrap, setOptions } from "vue-gtag";
import { namespace } from "vuex-class";

const stylingNamespace = namespace("Styling");

@Component({
  components: {},
})
export default class App extends Vue {
  @stylingNamespace.Getter("stickyHeader")
  isHeaderSticky!: boolean;

  get gaTag() {
    return this.$store.getters["Config/all"]?.gaTag;
  }

  get classes() {
    return this.isHeaderSticky ? "padding-sticky-header" : "";
  }

  enablePlugin() {
    setOptions({
      config: { id: this.gaTag },
    });

    bootstrap().then((gtag) => {
      // all done!
    });
  }

  async created() {
    await this.$store.dispatch("Config/fetchAll");
    if (this.gaTag) {
      this.enablePlugin();
    }
  }
}
