import { User } from "@/models/user.model";
import axios from "@/plugins/axios.plugin";
import BaseService from "./base-service";

class StatisticsService extends BaseService<any> {
  get endpoint() {
    return "/statistics";
  }

  async fetchUsersLast7Days(): Promise<User[]> {
    const response = await axios.get<any>(`${this.endpoint}/new-users-count`);
    return response.data;
  }

  async fetchLatestUsers(): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/new-users`);
    return response.data;
  }

  async fetchSocialPostCount(): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/social-posts`);
    return response.data;
  }

  async fetchAttendeesCount(): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/attendees`);
    return response.data;
  }

  async fetchRegistrationCount(): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/registrations`);
    return response.data;
  }

  async fetchLogin(): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/login`);
    return response.data;
  }

  async fetchUniqueLogin(): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/uniqueLogin`);
    return response.data;
  }

  async fetchViews(): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/views`);
    return response.data;
  }
}
export default new StatisticsService();
