import { Payment } from "@/models/payment.model";
import PaymentsService from "@/services/PaymentsService";
import Vue from "vue";

interface PaymentsState {
  all: Payment[];
  current: Payment;
}

const Payments = {
  namespaced: true,
  state: {
    all: [],
    current: new Payment(),
    fields: [],
  },
  getters: {
    all: (state: PaymentsState) => state.all,
    current: (state: PaymentsState) => state.current,
  },
  mutations: {
    SET_ALL(state: PaymentsState, payments: Payment[]) {
      state.all = payments;
    },
    SET_CURRENT(state: PaymentsState, payment: Payment) {
      Vue.set(state, "current", payment);
    },
  },
  actions: {
    async fetchAll({ commit }: any) {
      const pages = await PaymentsService.fetchAllUnpaginated();
      commit("SET_ALL", pages);
    },
    async create({ commit }: any, data: Payment) {
      const response = await PaymentsService.create(data);
      commit("SET_CURRENT", response);
      return response;
    },
    async update({ commit }: any, data: Payment) {
      const response = await PaymentsService.update(data.id as string, data);
      return response;
    },
    async setPaid({ commit }: any, id: string) {
      const response = await PaymentsService.setPaid(id);
      commit("SET_CURRENT", response);
      return response;
    },
    async setUnpaid({ commit }: any, id: string) {
      const response = await PaymentsService.setUnpaid(id);
      commit("SET_CURRENT", response);
      return response;
    },
    async delete({ commit }: any, id: string) {
      const response = await PaymentsService.delete(id);
    },
    async fetchById({ commit }: any, id: string) {
      const response = await PaymentsService.fetchOne(id);
      commit("SET_CURRENT", response);
      return response;
    },
  },
} as any;

export default Payments;
