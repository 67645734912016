import SettingsService from "@/services/SettingsService";

interface CustomCssState {
  all: { value: string };
}

const CustomCss = {
  namespaced: true,
  state: {
    all: { value: "" },
  },
  getters: {
    all: (state: CustomCssState) => state.all,
  },
  mutations: {
    SET_ALL(state: CustomCssState, customCss: { value: string }) {
      state.all = customCss;
    },
  },
  actions: {
    async fetchAll({ commit }: any, filter: any) {
      const customCss = await SettingsService.fetchOne("custom-css");
      commit("SET_ALL", customCss.setting_value);
    },
    async update({ commit }: any, payload: any) {
      const result = await SettingsService.update("custom-css", payload);
    },
  },
} as any;

export default CustomCss;
