import { User } from "@/models/user.model";
import StatisticsService from "@/services/StatisticsService";

const Statistics = {
  namespaced: true,
  state: {
    login: {},
    uniqueLogin: {},
    views: {},
    usersLast7Days: 0,
    registrations: 0,
    attendees: 0,
    socialPosts: 0,
    latestUsers: [],
  },
  getters: {
    login: (state: { login: any }) => state.login,
    usersLast7Days: (state: any) => state.usersLast7Days,
    registrations: (state: any) => state.registrations,
    socialPosts: (state: any) => state.socialPosts,
    attendees: (state: any) => state.attendees,
    latestUsers: (state: any) => state.latestUsers,
  },
  mutations: {
    SET_LOGIN(state: { login: any }, login: any) {
      state.login = login;
    },
    SET_UNIQUE_LOGIN(state: { uniqueLogin: any }, uniqueLogin: any) {
      state.uniqueLogin = uniqueLogin;
    },
    SET_VIEWS(state: { views: any }, views: any) {
      state.views = views;
    },
    SET_USERS_LAST_7_DAYS(state: any, count: number) {
      state.usersLast7Days = count;
    },
    SET_REGISTRATIONS(state: any, count: number) {
      state.registrations = count;
    },
    SET_SOCIAL_POSTS(state: any, count: number) {
      state.socialPosts = count;
    },
    SET_LATEST_USERS(state: any, users: User[]) {
      state.latestUsers = users;
    },
    SET_ATTENDEES(state: any, count: number) {
      state.attendees = count;
    },
  },
  actions: {
    async fetchLogin({ commit }: any) {
      const login = await StatisticsService.fetchLogin();
      commit("SET_LOGIN", login);
    },
    async fetchUniquelogin({ commit }: any) {
      const uniqueLogin = await StatisticsService.fetchUniqueLogin();
      commit("SET_UNIQUE_LOGIN", uniqueLogin);
    },
    async fetchViews({ commit }: any) {
      const views = await StatisticsService.fetchViews();
      commit("SET_VIEWS", views);
    },
    async fetchUsersLast7Days({ commit }: any) {
      const count = await StatisticsService.fetchUsersLast7Days();
      commit("SET_USERS_LAST_7_DAYS", count);
    },
    async fetchRegistrationCount({ commit }: any) {
      const count = await StatisticsService.fetchRegistrationCount();
      commit("SET_REGISTRATIONS", count);
    },
    async fetchSocialPostCount({ commit }: any) {
      const count = await StatisticsService.fetchSocialPostCount();
      commit("SET_SOCIAL_POSTS", count);
    },
    async fetchAttendees({ commit }: any) {
      const count = await StatisticsService.fetchAttendeesCount();
      commit("SET_ATTENDEES", count);
    },
    async fetchLatestUsers({ commit }: any) {
      const users = await StatisticsService.fetchLatestUsers();
      commit("SET_LATEST_USERS", users);
    },
  },
} as any;

export default Statistics;
