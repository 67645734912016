import { BaseModel } from "./base.model";

export enum CustomFormElementType {
  text = "text",
  textarea = "textarea",
  email = "email",
  password = "password",
  country = "country",
  number = "number",
  checkbox = "checkbox",
  radio = "radio",
  dropdown = "dropdown",
  date = "date",
  datetime = "datetime",
  image = "image",
  attachment = "attachment",
  static = "static-text",
  tags = "tags",
  workshop = "workshop",
  hidden = "hidden",
}

export class CustomForm extends BaseModel {
  constructor(
    public successMail: boolean = false,
    public mailTemplate: string = "",
    public link: string = "",
    public steps: CustomFormStep[] = []
  ) {
    super();
  }
}

export class CustomFormStep {
  constructor(public title: string, public elements: CustomFormElement[]) {}
}

export class CustomFormElement {
  constructor(
    public label: string = "",
    public type: CustomFormElementType = CustomFormElementType.text,
    public field: string = "",
    public rules: string = "",
    public placeholder: string = "",
    public extensions: string = "",
    public sizes: CustomFormElementSizes = new CustomFormElementSizes(),
    public hidden?: string,
    public values: string[] = []
  ) {}
}

export class CustomFormElementSizes {
  constructor(
    public xs: number = 12,
    public sm: number = 12,
    public md: number = 12,
    public lg: number = 12
  ) {}
}
