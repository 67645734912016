import { StatisticsConfig } from "@/models/statistics-config.model";
import SettingsService from "@/services/SettingsService";

interface StatisticsConfigState {
  all: StatisticsConfig;
}

const StatisticsConfigStore = {
  namespaced: true,
  state: {
    all: new StatisticsConfig(),
  },
  getters: {
    all: (state: StatisticsConfigState) => state.all,
  },
  mutations: {
    SET_ALL(
      state: StatisticsConfigState,
      paymentCalculation: StatisticsConfig
    ) {
      state.all = paymentCalculation;
    },
  },
  actions: {
    async fetchAll({ commit }: any, filter: any) {
      const statisticsConfig = await SettingsService.fetchOne(
        "statistics-config"
      );
      commit("SET_ALL", statisticsConfig.setting_value);
    },
    async update({ commit }: any, payload: any) {
      const result = await SettingsService.update("statistics-config", payload);
    },
  },
} as any;

export default StatisticsConfigStore;
