import SettingsService from "@/services/SettingsService";

interface LegalState {
  all: { cookie: any; privacy: any; generalConditions: any };
}

const Legal = {
  namespaced: true,
  state: {
    all: { generalConditions: {}, cookie: {}, privacy: {} },
  },
  getters: {
    generalConditions: (state: LegalState) => state.all?.generalConditions,
    cookie: (state: LegalState) => state.all?.cookie,
    privacy: (state: LegalState) => state.all?.privacy,
    legal: (state: LegalState) => state.all,
  },
  mutations: {
    SET_ALL(state: LegalState, legal: any) {
      state.all = legal;
    },
  },
  actions: {
    async fetchAll({ commit }: any) {
      console.log("FETCH ALL");
      const features = await SettingsService.fetchOnePublic("legal");
      commit("SET_ALL", features.setting_value);
    },
    async update({ commit }: any, payload: any) {
      const result = await SettingsService.update("legal", payload);
    },
  },
} as any;

export default Legal;
