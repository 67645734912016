import { AvailableField } from "@/models/available-field.model";
import { UserFilter } from "@/models/user-filter.model";
import UserFilterService from "@/services/UserFilterService";
import Vue from "vue";

interface UserFilterState {
  all: UserFilter[];
  current: UserFilter;
  fields: AvailableField[];
  count: number;
}

const UserFilters = {
  namespaced: true,
  state: {
    all: [],
    current: new UserFilter(),
    fields: [],
    count: 0,
  },
  getters: {
    all: (state: UserFilterState) => state.all,
    current: (state: UserFilterState) => state.current,
    availableFields: (state: UserFilterState) => state.fields,
    count: (state: UserFilterState) => state.count,
  },
  mutations: {
    SET_ALL(state: UserFilterState, pages: any) {
      state.all = pages;
    },
    SET_CURRENT(state: UserFilterState, page: UserFilter) {
      Vue.set(state, "current", page);
    },
    SET_AVAILABLE_FIELDS(state: UserFilterState, fields: string[]) {
      Vue.set(state, "fields", fields);
    },
    SET_COUNT(state: UserFilterState, count: number) {
      Vue.set(state, "count", count);
    },
  },
  actions: {
    async fetchAll({ commit }: any) {
      const pages = await UserFilterService.fetchAllUnpaginated();
      commit("SET_ALL", pages);
    },
    async fetchCount({ commit }: any, id: string) {
      const count = await UserFilterService.fetchCount(id);
      commit("SET_COUNT", count);
    },
    async create({ commit }: any, data: UserFilter) {
      const response = await UserFilterService.create(data);
      commit("SET_CURRENT", response);
      return response;
    },
    async update({ commit }: any, data: UserFilter) {
      const response = await UserFilterService.update(data.id as string, data);
      return response;
    },
    async delete({ commit }: any, id: string) {
      const response = await UserFilterService.delete(id);
    },
    async fetchById({ commit }: any, id: string) {
      const response = await UserFilterService.fetchOne(id);
      commit("SET_CURRENT", response);
      return response;
    },
    async fetchUsersForFilter({ commit }: any, id: string) {
      const response = await UserFilterService.fetchUsersForFilter(id);
      return response;
    },
    async fetchAvailableFields({ commit }: any) {
      const response = await UserFilterService.fetchAvailableFields();
      commit("SET_AVAILABLE_FIELDS", response);
      return response;
    },
  },
} as any;

export default UserFilters;
