import Vue from "vue";
import Vuex from "vuex";
import Auth from "./modules/auth.module";
import Config from "./modules/config.module";
import Popup from "./modules/popup.module";
import Translations from "./modules/translations.module";
import Dash from "./modules/dashboard.module";
import Livestreams from "./modules/livestream.module";
import MediaAlbums from "./modules/media-albums.module";
import Media from "./modules/media.module";
import Styling from "./modules/styling.module";
import Users from "./modules/users.module";
import Notifications from "./modules/notifications.module";
import Features from "./modules/features.module";
import Sponsors from "./modules/sponsors.module";
import Logs from "./modules/logs.module";
import Languages from "./modules/languages.module";
import Header from "./modules/header.module";
import Pages from "./modules/pages.module";
import Freshchat from "./modules/freshchat.module";
import Whereby from "./modules/whereby.module";
import Tags from "./modules/tags.module";
import Mails from "./modules/mails.module";
import Registration from "./modules/registration.module";
import Statistics from "./modules/statistics.module";
import SocialPosts from "./modules/socialposts.module";
import SocialpostTopics from "./modules/socialpost-topics.module";
import Schedule from "./modules/schedule.module";
import Rooms from "./modules/rooms.module";
import IFlyChat from "./modules/iflychat.module";
import Whitelist from "./modules/whitelist.module";
import CustomForms from "./modules/custom-forms.module";
import DefaultMails from "./modules/default-mails.module";
import GlobalAccessCode from "./modules/global-access-code.module";
import Workshops from "./modules/workshops.module";
import TicketTemplates from "./modules/ticket-templates.module";
import EventSessions from "./modules/event-sessions.module";
import UserFilters from "./modules/user-filters.module";
import Blacklist from "./modules/blacklist.module";
import Payments from "./modules/payments.module";
import Legal from "./modules/legal.module";
import PaymentCalculation from "./modules/payment-calculation.module";
import StatisticsConfig from "./modules/statistics-config.module";
import CustomCSS from "./modules/custom-css.module";
import UserTableConfig from "./modules/user-table-config.module";

Vue.use(Vuex);

const store = new Vuex.Store<any>({
  modules: {
    Config,
    UserFilters,
    Auth,
    Popup,
    Translations,
    Livestreams,
    Dash,
    Styling,
    Users,
    Notifications,
    Features,
    Sponsors,
    Languages,
    Logs,
    Header,
    Pages,
    Freshchat,
    Whereby,
    Tags,
    Mails,
    Registration,
    Statistics,
    SocialPosts,
    Schedule,
    Rooms,
    IFlyChat,
    Whitelist,
    CustomForms,
    DefaultMails,
    GlobalAccessCode,
    Workshops,
    TicketTemplates,
    EventSessions,
    Blacklist,
    SocialpostTopics,
    Payments,
    Legal,
    PaymentCalculation,
    StatisticsConfig,
    CustomCSS,
    Media,
    MediaAlbums,
    UserTableConfig,
  },
});

export default store;
