import { Media } from "@/models/media.model";
import MediaService from "@/services/MediaService";
import Vue from "vue";

interface MediaState {
  all: Media[];
  current: Media;
}

const MediaStore = {
  namespaced: true,
  state: {
    all: [],
    current: new Media(),
  },
  getters: {
    all: (state: MediaState) => state.all,
    current: (state: MediaState) => state.current,
  },
  mutations: {
    SET_ALL(state: MediaState, media: Media[]) {
      state.all = media;
    },
    SET_CURRENT(state: MediaState, media: Media) {
      Vue.set(state, "current", media);
    },
  },
  actions: {
    async fetchAll({ commit }: any) {
      const media = await MediaService.fetchAllUnpaginated();
      commit("SET_ALL", media);
    },
    async create({ commit }: any, data: Media) {
      const response = await MediaService.create(data);
      commit("SET_CURRENT", response);
      return response;
    },
    async updateById({ commit }: any, data: Media) {
      const response = await MediaService.update(data.id as string, data);
      return response;
    },
    async delete({ commit }: any, id: string) {
      const response = await MediaService.delete(id);
    },
    async fetchById({ commit }: any, id: string) {
      const response = await MediaService.fetchOne(id);
      commit("SET_CURRENT", response);
      return response;
    },
  },
} as any;

export default MediaStore;
