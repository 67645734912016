import { AvailableField } from "@/models/available-field.model";
import { SocialpostTopic } from "@/models/socialpost-topic.model";
import SocialpostTopicsService from "@/services/SocialpostTopicsService";
import Vue from "vue";

interface SocialpostTopicState {
  all: SocialpostTopic[];
  current: SocialpostTopic;
}

const SocialpostTopics = {
  namespaced: true,
  state: {
    all: [],
    current: new SocialpostTopic(),
  },
  getters: {
    all: (state: SocialpostTopicState) => state.all,
    current: (state: SocialpostTopicState) => state.current,
  },
  mutations: {
    SET_ALL(state: SocialpostTopicState, pages: any) {
      state.all = pages;
    },
    SET_CURRENT(state: SocialpostTopicState, page: SocialpostTopic) {
      Vue.set(state, "current", page);
    },
  },
  actions: {
    async fetchAll({ commit }: any) {
      const pages = await SocialpostTopicsService.fetchAllUnpaginated();
      commit("SET_ALL", pages);
    },
    async create({ commit }: any, data: SocialpostTopic) {
      const response = await SocialpostTopicsService.create(data);
      commit("SET_CURRENT", response);
      return response;
    },
    async update({ commit }: any, data: SocialpostTopic) {
      const response = await SocialpostTopicsService.update(
        data.id as string,
        data
      );
      return response;
    },
    async delete({ commit }: any, id: string) {
      const response = await SocialpostTopicsService.delete(id);
    },
    async fetchById({ commit }: any, id: string) {
      const response = await SocialpostTopicsService.fetchOne(id);
      commit("SET_CURRENT", response);
      return response;
    },
  },
} as any;

export default SocialpostTopics;
