import { SocialpostTopic } from "@/models/socialpost-topic.model";
import BaseService from "./base-service";

class SocialpostTopicsService extends BaseService<SocialpostTopic> {
  get endpoint() {
    return "/socialpost-topics";
  }
}

export default new SocialpostTopicsService();
