import axios from "@/plugins/axios.plugin";
import { Payment } from "@/models/payment.model";
import BaseService from "./base-service";

class PaymentsService extends BaseService<Payment> {
  get endpoint() {
    return "/payments";
  }

  async setPaid(id: string): Promise<Payment> {
    const response = await axios.put<Payment>(`${this.endpoint}/${id}/paid`);
    return response.data;
  }

  async setUnpaid(id: string): Promise<Payment> {
    const response = await axios.put<Payment>(
      `${this.endpoint}/${id}/not-paid`
    );
    return response.data;
  }

  async generatePaymentForRegistration(): Promise<Payment> {
    const response = await axios.post<Payment>(`${this.endpoint}/registration`);
    return response.data;
  }

  async sendConfirmation(id: string): Promise<void> {
    const response = await axios.post<void>(
      `${this.endpoint}/${id}/send-confirmation`
    );
    return response.data;
  }

  async export(): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/export`, {
      responseType: "arraybuffer",
    });
    return response.data;
  }
}

export default new PaymentsService();
