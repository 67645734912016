import { BaseModel } from "./base.model";
import { Translatable } from "./translatable.model";

export class MediaAlbum extends BaseModel {
  constructor(
    public title: Translatable = new Translatable(),
    public description: Translatable = new Translatable()
  ) {
    super();
  }
}
