import { SocialPostLike } from "@/models/social-post-like.model";
import { SocialPost } from "@/models/social-post.model";
import SocialPostsService from "@/services/SocialPostsService";

interface SocialPostState {
  lastPagination: any;
  all: SocialPost[];
  likes: SocialPostLike[];
}

const SocialPosts = {
  namespaced: true,
  state: {
    all: [],
    likes: [],
    lastPagination: {},
  },
  getters: {
    all: (state: SocialPostState) => state.all,
    likes: (state: SocialPostState) => state.likes,
    hasNext: (state: SocialPostState) => state.lastPagination?.next_page_url,
  },
  mutations: {
    SET_ALL(state: SocialPostState, all: SocialPost[]) {
      state.all = all;
    },
    SET_LIKES(state: SocialPostState, likes: SocialPostLike[]) {
      state.likes = likes;
    },
    SET_PAGINATION(state: SocialPostState, pagination: any) {
      state.lastPagination = pagination;
    },
    ADD_RESULT(state: SocialPostState, all: any) {
      state.all = state.all.concat(all);
    },
    PREPEND_POST(state: SocialPostState, post: SocialPost) {
      console.log(post.social_post_id);
      if (!post.social_post_id) {
        // add as main post
        state.all.unshift(post);
      }
      //add as reply
      for (const parentPost of state.all) {
        if (post.social_post_id?.toString() === parentPost.id.toString()) {
          parentPost.socialposts?.push(post);
          return;
        }
      }
    },
    DELETE(state: SocialPostState, id: string) {
      const index = state.all.findIndex((p: SocialPost) => id === p.id);
      if (index === -1) {
        state.all.forEach((p: SocialPost) => {
          if (!p.socialposts?.length) {
            return;
          }
          const ind = p.socialposts.findIndex((p: SocialPost) => id === p.id);
          if (ind === -1) {
            return;
          }
          p.socialposts.splice(ind, 1);
        });
        return;
      }
      state.all.splice(index, 1);
    },
    SET_LIKED(state: SocialPostState, id: string) {
      const post = state.all.find((p: SocialPost) => id === p.id);
      if (post) {
        post.is_liked = true;
        post.likes_count++;
        return;
      }
      state.all.forEach((p: SocialPost) => {
        if (!p.socialposts?.length) {
          return;
        }
        const post = p.socialposts.find((p: SocialPost) => id === p.id);
        if (!post) {
          return;
        }
        post.is_liked = true;
        post.likes_count++;
      });
    },
    REMOVE_LIKED(state: SocialPostState, id: string) {
      const post = state.all.find((p: SocialPost) => id === p.id);
      if (post) {
        post.is_liked = false;
        post.likes_count--;
        return;
      }
      state.all.forEach((p: SocialPost) => {
        if (!p.socialposts?.length) {
          return;
        }
        const post = p.socialposts.find((p: SocialPost) => id === p.id);
        if (!post) {
          return;
        }
        post.is_liked = false;
        post.likes_count--;
      });
    },
  },
  actions: {
    async fetchAll({ commit }: any, topic?: string) {
      const posts = await SocialPostsService.fetchAllByTopic(topic);
      commit("SET_ALL", posts.data);
      commit("SET_PAGINATION", posts);
    },
    async fetchNext({ commit, state }: any) {
      const posts = await SocialPostsService.fetchCursor(
        state.lastPagination.next_page_url
      );
      commit("ADD_RESULT", posts.data);
      commit("SET_PAGINATION", posts);
    },
    async create({ commit }: any, payload: any) {
      const result = await SocialPostsService.create(payload);
      commit("PREPEND_POST", result);
    },
    async delete({ commit }: any, id: string) {
      await SocialPostsService.delete(id);
      commit("DELETE", id);
    },
    async like({ commit }: any, id: string) {
      await SocialPostsService.like(id);
      commit("SET_LIKED", id);
    },
    async unlike({ commit }: any, id: string) {
      await SocialPostsService.unlike(id);
      commit("REMOVE_LIKED", id);
    },
    async likes({ commit }: any, id: string) {
      commit("SET_LIKES", []);
      const likes = await SocialPostsService.fetchLikes(id);
      commit("SET_LIKES", likes);
    },
  },
} as any;

export default SocialPosts;
