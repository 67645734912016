import { User } from "@/models/user.model";
import axios from "@/plugins/axios.plugin";
import { PaginatedResult } from "@/shared/types/paginated-result.class";
import BaseService from "./base-service";

class UsersService extends BaseService<User> {
  get endpoint() {
    return "/users";
  }

  async fetchOnline(): Promise<User> {
    const response = await axios.get<any>(`${this.endpoint}/online`, {
      responseType: "arraybuffer",
    });
    return response.data;
  }

  async fetchPublicById(id: string): Promise<User> {
    const response = await axios.get<any>(`${this.endpoint}/public/${id}`);
    return response.data;
  }

  async fetchAllPublic(params?: any): Promise<PaginatedResult<User>> {
    const response = await axios.get<PaginatedResult<User>>(
      `${this.endpoint}/public`,
      {
        params,
      }
    );

    return response.data;
  }

  async fetchAllUnpaginated(params?: any): Promise<User[]> {
    const response = await axios.get<User[]>(`${this.endpoint}/all`, {
      params,
    });

    return response.data;
  }

  async fetchLeaderboard(params?: any): Promise<User[]> {
    const response = await axios.get<User[]>(`${this.endpoint}/leaderboard`, {
      params,
    });

    return response.data;
  }

  async export(language: string): Promise<any> {
    const response = await axios.get<any>(`${this.endpoint}/export`, {
      responseType: "arraybuffer",
      params: { language },
    });
    return response.data;
  }

  async import(file: any): Promise<any> {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(`${this.endpoint}/import`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      responseType: "json",
    });
    return response.data;
  }

  async setHasPaid(userId: string): Promise<User> {
    const response = await axios.put<User>(`${this.endpoint}/${userId}/paid`);
    return response.data;
  }

  async setHasNotPaid(userId: string): Promise<User> {
    const response = await axios.put<User>(
      `${this.endpoint}/${userId}/not-paid`
    );
    return response.data;
  }
  async fetchGroups(): Promise<string[]> {
    const response = await axios.get<string[]>(`${this.endpoint}-groups`);
    return response.data;
  }
}

export default new UsersService();
