import SettingsService from "@/services/SettingsService";

interface PaymentCalculationState {
  all: string;
}

const Features = {
  namespaced: true,
  state: {
    all: "",
  },
  getters: {
    all: (state: PaymentCalculationState) => state.all,
  },
  mutations: {
    SET_ALL(state: PaymentCalculationState, paymentCalculation: string) {
      state.all = paymentCalculation;
    },
  },
  actions: {
    async fetchAll({ commit }: any, filter: any) {
      const features = await SettingsService.fetchOne("payment-calculation");
      commit("SET_ALL", features.setting_value);
    },
    async update({ commit }: any, payload: any) {
      const result = await SettingsService.update(
        "payment-calculation",
        payload
      );
    },
  },
} as any;

export default Features;
