import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store/index";
const Home = () => import("@/views/Home.vue");
const Livestream = () => import("@/views/Livestream.vue");
const Profile = () => import("@/views/Profile.vue");
const Admin = () => import("@/views/Admin.vue");
const Authentication = () => import("@/views/Authentication.vue");
const ComingSoon = () => import("@/views/ComingSoon.vue");
const MobileChat = () => import("@/views/MobileChat.vue");
const ZoomPlayer = () => import("@/components/video/ZoomPlayer.vue");
const CookiePrivacy = () => import("@/components/GDPR/CookiePrivacy.vue");
const LanguagePick = () => import("@/views/LanguagePick.vue");
const SocialView = () => import("@/views/SocialView.vue");
const Dashboard = () => import("@/views/Dashboard.vue");
const StaticPage = () => import("@/views/StaticPage.vue");
const HomeConfig = () => import("@/components/admin/home/HomeConfig.vue");
const StyleConfig = () => import("@/components/admin/style/StyleConfig.vue");
const LegalConfig = () => import("@/components/admin/legal/LegalConfig.vue");
const WherebyConfig = () =>
  import("@/components/admin/whereby/WherebyConfig.vue");
const FeatureConfig = () =>
  import("@/components/admin/features/FeatureConfig.vue");
const PaymentConfig = () =>
  import("@/components/admin/payments/PaymentConfig.vue");
const Notifications = () =>
  import("@/components/admin/notifications/Notifications.vue");
const Mails = () => import("@/components/admin/mails/MailTemplatesTable.vue");
const DefaultMails = () => import("@/components/admin/mails/DefaultMails.vue");
const SendMail = () => import("@/components/admin/mails/SendMail.vue");
const SendMailToGroup = () =>
  import("@/components/admin/mails/SendMailToGroup.vue");
const Blacklist = () => import("@/components/admin/blacklist/Blacklist.vue");
const Registration = () => import("@/components/Registration.vue");
const LivestreamConfig = () =>
  import("@/components/admin/livestreams/LivestreamConfig.vue");
const AdminLogin = () => import("@/views/AdminLogin.vue");
const Users = () => import("@/components/admin/users/Users.vue");
const RegistrationConfig = () =>
  import("@/components/admin/registration/RegistrationConfig.vue");
const UserDetail = () => import("@/components/admin/users/UserDetail.vue");
const LivestreamDetail = () =>
  import("@/components/admin/livestreams/LivestreamDetail.vue");
const StaticPages = () =>
  import("@/components/admin/static-pages/StaticPages.vue");
const StaticPagesDetail = () =>
  import("@/components/admin/static-pages/StaticPagesDetail.vue");

const UserFilters = () =>
  import("@/components/admin/user-filters/UserFilters.vue");
const UserFiltersDetail = () =>
  import("@/components/admin/user-filters/UserFiltersDetail.vue");

const Payments = () => import("@/components/admin/payments/Payments.vue");
const PaymentDetail = () =>
  import("@/components/admin/payments/PaymentDetail.vue");
const GeneratePayment = () => import("@/views/GeneratePayment.vue");
const Workshops = () => import("@/components/admin/workshops/Workshops.vue");
const WorkshopDetail = () =>
  import("@/components/admin/workshops/WorkshopDetail.vue");
const EventSessions = () =>
  import("@/components/admin/event-sessions/EventSessions.vue");
const EventSessionDetail = () =>
  import("@/components/admin/event-sessions/EventSessionDetail.vue");
const HeaderConfig = () => import("@/components/admin/header/HeaderConfig.vue");
const FreshchatConfig = () =>
  import("@/components/admin/freshchat/FreshchatConfig.vue");
const TranslationsConfig = () =>
  import("@/components/admin/language/TranslationsConfig.vue");
const MediaAlbums = () => import("@/components/admin/media/MediaAlbums.vue");
const MediaView = () => import("@/components/admin/media/MediaView.vue");
const SponsorsConfig = () =>
  import("@/components/admin/sponsors/SponsorsConfig.vue");
const MediaDetail = () => import("@/components/admin/media/MediaDetail.vue");
const MediaAlbumDetail = () =>
  import("@/components/admin/media/MediaAlbumDetail.vue");
const MailTemplateDetail = () =>
  import("@/components/admin/mails/MailTemplateDetail.vue");
const PasswordRequest = () => import("@/components/PasswordRequest.vue");
const UserRoles = () => import("@/components/admin/users/UserRoles.vue");
const Statistics = () => import("@/components/admin/statistics/Statistics.vue");
const StatisticsConfigView = () =>
  import("@/components/admin/statistics/StatisticsConfigView.vue");
const BasicInfo = () => import("@/components/admin/BasicInfo.vue");
const Rooms = () => import("@/components/admin/rooms/Rooms.vue");
const RoomDetail = () => import("@/components/admin/rooms/RoomDetail.vue");
const RoomsOverview = () => import("@/views/RoomsOverview.vue");
const Leaderboard = () => import("@/views/Leaderboard.vue");
const ScheduleConfig = () =>
  import("@/components/admin/schedule/ScheduleConfig.vue");
const ScheduleDetail = () =>
  import("@/components/admin/schedule/ScheduleDetail.vue");
const Whitelist = () => import("@/components/admin/users/Whitelist.vue");
const UserProfile = () => import("@/views/UserProfile.vue");
const UserList = () => import("@/views/UserList.vue");
const UserLogs = () => import("@/components/admin/users/UserLogs.vue");
const CustomFormDetail = () =>
  import("@/components/admin/custom-forms/CustomFormDetail.vue");
const CustomForms = () =>
  import("@/components/admin/custom-forms/CustomForms.vue");
const Form = () => import("@/views/Form.vue");
const TicketTemplateDetail = () =>
  import("@/components/admin/tickets/TicketTemplateDetail.vue");
const TicketTemplates = () =>
  import("@/components/admin/tickets/TicketTemplates.vue");
const GenerateTicketForGroup = () =>
  import("@/components/admin/tickets/GenerateTicketForGroup.vue");
const GenerateTicket = () =>
  import("@/components/admin/tickets/GenerateTicket.vue");
const SocialpostTopics = () =>
  import("@/components/admin/socialpost-topics/SocialpostTopics.vue");
const SocialpostTopicDetail = () =>
  import("@/components/admin/socialpost-topics/SocialpostTopicDetail.vue");
const CustomCss = () => import("@/components/admin/style/CustomCss.vue");

import { authGuard } from "../auth0/authGuard";

Vue.use(VueRouter);

let routes: Array<RouteConfig> = [];

routes = [
  {
    path: "/",
    name: "home",
    meta: {
      authRequired: true,
    },
    component: Home,
    children: [
      {
        path: "/languagepick",
        alias: ["/language"],
        name: "languagePick",
        meta: {
          authRequired: false,
        },
        component: LanguagePick,
      },
      {
        path: "/",
        alias: ["/home", "/dashboard"],
        name: "dashboard",
        component: Dashboard,
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/livestream",
        name: "livestream",
        meta: {
          authRequired: true,
        },
        props: true,
        component: Livestream,
      },
      {
        path: "/social",
        name: "social",
        meta: {
          authRequired: true,
        },
        component: SocialView,
      },
      {
        path: "/rooms",
        name: "rooms",
        meta: {
          authRequired: true,
        },
        component: RoomsOverview,
      },
      {
        path: "/leaderboard",
        name: "leaderboard",
        meta: {
          authRequired: true,
        },
        component: Leaderboard,
      },
      {
        path: "/profile",
        name: "profile",
        meta: {
          authRequired: true,
        },
        component: Profile,
      },
      {
        path: "/user/:id",
        name: "user",
        meta: {
          authRequired: true,
        },
        component: UserProfile,
      },
      {
        path: "/users",
        name: "user",
        meta: {
          authRequired: true,
        },
        component: UserList,
      },
      {
        path: "/form/:id",
        name: "form",
        meta: {
          authRequired: true,
        },
        component: Form,
      },
      {
        path: "/authentication",
        name: "authentication",
        component: Authentication,
      },
      {
        path: "/registration",
        name: "RegistrationForm",
        component: Registration,
        meta: { authRequired: true },
      },
      {
        path: "/password-request",
        name: "password-request",
        component: PasswordRequest,
      },
      {
        path: "/password-reset/:token",
        name: "password-reset",
        props: true,
        component: PasswordRequest,
      },
      {
        path: "/zoom",
        name: "zoom",
        component: ZoomPlayer,
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/coming-soon",
        name: "comingSoon",
        component: ComingSoon,
      },
      {
        path: "/mobile",
        name: "mobileChat",
        component: MobileChat,
      },
      {
        path: "/cookie-privacy",
        name: "cookie",
        component: CookiePrivacy,
      },
      {
        path: "/social/:topic?",
        name: "social",
        component: SocialView,
        meta: {
          authRequired: true,
        },
      },
      {
        path: "/pages/:link",
        name: "static-page",
        component: StaticPage,
      },
      {
        path: "/generate-payment",
        name: "generate-payment",
        component: GeneratePayment,
      },
    ],
  },
  {
    path: "/admin",
    name: "admin",
    meta: {
      authRequired: true,
    },
    component: Admin,
    children: [
      {
        path: "login",
        name: "admin-login",
        component: AdminLogin,
        meta: {
          hideSidebar: true,
        },
      },
      {
        path: "user-logs",
        name: "user-logs",
        component: UserLogs,
      },
      {
        path: "basic-info",
        name: "basic-info",
        component: BasicInfo,
      },
      {
        path: "home-config",
        name: "home-config",
        component: HomeConfig,
      },
      {
        path: "livestream-config",
        name: "livestream-config",
        component: LivestreamConfig,
      },
      {
        path: "livestream-detail",
        name: "livestream-detail",
        component: LivestreamDetail,
        props: true,
      },
      {
        path: "rooms",
        name: "room-config",
        component: Rooms,
      },
      {
        path: "rooms/create",
        name: "room-create",
        component: RoomDetail,
      },
      {
        path: "rooms/:id",
        name: "room-detail",
        component: RoomDetail,
      },
      {
        path: "schedule-config",
        name: "schedule-config",
        component: ScheduleConfig,
      },
      {
        path: "schedule-detail",
        name: "schedule-detail",
        component: ScheduleDetail,
      },
      {
        path: "style-config",
        name: "style-config",
        component: StyleConfig,
      },
      {
        path: "custom-css",
        name: "custom-css",
        component: CustomCss,
      },
      {
        path: "feature-config",
        name: "feature-config",
        component: FeatureConfig,
      },
      {
        path: "payment-config",
        name: "payment-config",
        component: PaymentConfig,
      },
      {
        path: "legal-config",
        name: "legal-config",
        component: LegalConfig,
      },
      {
        path: "whereby-config",
        name: "whereby-config",
        component: WherebyConfig,
      },
      {
        path: "notifications",
        name: "notifications",
        component: Notifications,
      },
      {
        path: "default-mails",
        name: "default-mails",
        component: DefaultMails,
      },
      {
        path: "mails",
        name: "mails",
        component: Mails,
      },
      {
        path: "send-mail",
        name: "sendMail",
        component: SendMail,
      },
      {
        path: "send-mail-to-group",
        name: "sendMailToGroup",
        component: SendMailToGroup,
      },
      {
        path: "user-detail",
        name: "user-detail",
        component: UserDetail,
      },
      {
        path: "users",
        name: "users",
        component: Users,
      },
      {
        path: "custom-forms/create",
        name: "create-custom-form",
        component: CustomFormDetail,
      },
      {
        path: "custom-forms/:id",
        name: "custom-form-detail",
        component: CustomFormDetail,
      },
      {
        path: "custom-forms",
        name: "custom-forms",
        component: CustomForms,
      },
      {
        path: "registration",
        name: "registration-config",
        component: RegistrationConfig,
      },
      {
        path: "static-pages",
        name: "static-pages",
        component: StaticPages,
      },
      {
        path: "page-detail/create",
        name: "page-detail-create",
        component: StaticPagesDetail,
        props: true,
      },
      {
        path: "page-detail/:id",
        name: "page-detail",
        component: StaticPagesDetail,
        props: true,
      },
      {
        path: "user-filters",
        name: "user-filters",
        component: UserFilters,
      },
      {
        path: "user-filters/create",
        name: "user-filters-detail-create",
        component: UserFiltersDetail,
        props: true,
      },
      {
        path: "user-filters/:id",
        name: "user-filters-detail",
        component: UserFiltersDetail,
        props: true,
      },
      {
        path: "payments",
        name: "payments",
        component: Payments,
      },
      {
        path: "payments/create",
        name: "payments-detail-create",
        component: PaymentDetail,
        props: true,
      },
      {
        path: "payments/:id",
        name: "payments-detail",
        component: PaymentDetail,
        props: true,
      },
      {
        path: "socialpost-topics",
        name: "socialpost-topics",
        component: SocialpostTopics,
      },
      {
        path: "socialpost-topics/create",
        name: "socialpost-topics-create",
        component: SocialpostTopicDetail,
        props: true,
      },
      {
        path: "socialpost-topics/:id",
        name: "socialpost-topics-detail",
        component: SocialpostTopicDetail,
        props: true,
      },
      {
        path: "workshops",
        name: "workshops",
        component: Workshops,
      },
      {
        path: "workshops/create",
        name: "workshop-create",
        component: WorkshopDetail,
      },
      {
        path: "workshops/:id",
        name: "workshop-detail",
        component: WorkshopDetail,
      },
      {
        path: "events",
        name: "event-sessions",
        component: EventSessions,
      },
      {
        path: "events/create",
        name: "event-session-create",
        component: EventSessionDetail,
      },
      {
        path: "events/:id",
        name: "event-session-detail",
        component: EventSessionDetail,
      },
      {
        path: "header-config",
        name: "header-config",
        component: HeaderConfig,
      },
      {
        path: "freshchat-config",
        name: "freshchat-config",
        component: FreshchatConfig,
      },
      {
        path: "whereby-config",
        name: "whereby-config",
        component: WherebyConfig,
      },
      {
        path: "translations-config",
        name: "translations-config",
        component: TranslationsConfig,
      },
      {
        path: "media",
        name: "media",
        component: MediaView,
      },
      {
        path: "media-albums",
        name: "media-albums",
        component: MediaAlbums,
      },
      {
        path: "media-album-create",
        name: "media-album-create",
        component: MediaAlbumDetail,
      },
      {
        path: "media-album/:id",
        name: "media-album-detail",
        component: MediaAlbumDetail,
      },
      {
        path: "media-detail-create",
        name: "media-detail-create",
        component: MediaDetail,
      },
      {
        path: "media-detail/:id",
        name: "media-detail",
        component: MediaDetail,
      },
      {
        path: "sponsors-config",
        name: "sponsors-config",
        component: SponsorsConfig,
      },
      {
        path: "mail-template-detail",
        name: "mail-template-detail",
        component: MailTemplateDetail,
      },
      {
        path: "user-roles",
        name: "user-roles",
        component: UserRoles,
      },
      {
        path: "statistics",
        name: "statistics",
        component: Statistics,
      },
      {
        path: "statistics-config",
        name: "statistics-config",
        component: StatisticsConfigView,
      },
      {
        path: "whitelist",
        name: "whitelist",
        component: Whitelist,
      },
      {
        path: "blacklist",
        name: "blacklist",
        component: Blacklist,
      },
      {
        path: "ticket-templates",
        name: "ticket-templates",
        component: TicketTemplates,
      },
      {
        path: "ticket-templates/create",
        name: "ticket-template-create",
        component: TicketTemplateDetail,
      },
      {
        path: "ticket-templates/generate",
        name: "generate-ticket",
        component: GenerateTicket,
      },
      {
        path: "ticket-templates/generate-for-group",
        name: "generate-ticket-for-group",
        component: GenerateTicketForGroup,
      },
      {
        path: "ticket-templates/:id",
        name: "ticket-template-detail",
        component: TicketTemplateDetail,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const promiseArray: any[] = [];
  promiseArray.push(store.dispatch("Header/fetchAll"));
  promiseArray.push(store.dispatch("Languages/fetchAll"));
  promiseArray.push(store.dispatch("Features/fetchAll"));
  promiseArray.push(store.dispatch("Config/fetchAll"));
  promiseArray.push(store.dispatch("Styling/fetchAll"));
  promiseArray.push(store.dispatch("Auth/me"));
  promiseArray.push(store.dispatch("Freshchat/fetchAll"));
  await Promise.all(promiseArray);
  const loginRequired = store.getters["Features/loginRequired"];
  const authentication = store.getters["Features/all"].authentication?.type;
  const comingSoon = store.getters["Features/all"].comingSoon;
  const languagePick = store.getters["Features/all"].languagePick;
  const landingPage = store.getters["Features/all"].landingPage;
  const registrationRequired = store.getters["Features/all"].registerForm;
  const paymentRequired = store.getters["Features/all"].paymentRequired;
  const language = localStorage.getItem("lang");
  const isAdmin = store.getters["Auth/isAdmin"];
  const isModerator = store.getters["Auth/isModerator"];
  const isLoggedIn = store.getters["Auth/isLoggedIn"];
  const registrationCompleted = store.getters["Auth/registrationCompleted"];
  const paymentCompleted = store.getters["Auth/paymentCompleted"];
  const preferredLivestream = store.getters["Auth/preferredLivestream"];
  if (to.path == "/" && !landingPage) {
    if (preferredLivestream) {
      return next({ name: "livestream", query: { id: preferredLivestream } });
    }
    return next({ name: "livestream" });
  }

  if (to.name === "admin-login") {
    return next();
  }

  if (to.path == "/admin") {
    return next({ name: "statistics" });
  }

  if (to.path.startsWith("/admin") && (isAdmin || isModerator)) {
    return next();
  }

  if (to.path.startsWith("/admin") && (!isAdmin || !isModerator)) {
    return next({ name: "admin-login" });
  }

  if (!loginRequired && to.name === "authentication") {
    return next({ name: "dashboard" });
  }

  if (!loginRequired || !to.meta?.authRequired) {
    return next();
  }

  if (
    !isAdmin &&
    isLoggedIn &&
    registrationRequired &&
    !registrationCompleted
  ) {
    if (to.name === "RegistrationForm") {
      return next();
    } else {
      return next({ name: "RegistrationForm" });
    }
  }

  if (!isAdmin && isLoggedIn && paymentRequired && !paymentCompleted) {
    if (to.name === "generate-payment") {
      return next();
    } else {
      return next({ name: "generate-payment" });
    }
  }

  if (loginRequired && isLoggedIn) {
    return next();
  }

  if (authentication === "auth0") {
    authGuard(to, from, next);
  } else if (authentication !== "none") {
    return next({ name: "authentication" });
  }

  if (languagePick && to.path !== "/language" && !language) {
    return next({ name: "languagePick" });
  }

  if (comingSoon && to.name !== "coming-soon" && !isAdmin) {
    return next({ name: "coming-soon" });
  }

  if (to.path == "/coming-soon" && !comingSoon) {
    return next({ name: "home" });
  }

  return next();
});

export default router;
